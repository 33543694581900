import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Button, Table, Card, Badge } from 'react-bootstrap';
import { useAuth } from '../AuthContext';
import CheckoutButton from '../CheckoutButton';
import UserDashboardNav from '../UserDashboardNav'; // Importuj UserDashboardNav
import './UserDashboard.css';
import api from '../api';

const UserDashboard = () => {
  const navigate = useNavigate();
  const { logout, authState, setAuthState } = useAuth();
  const [csrfToken, setCsrfToken] = useState('');
  const [selectedPrice, setSelectedPrice] = useState(0);
  const [showMessage, setShowMessage] = useState(false);

  const isPremium = authState.user ? authState.user.isPremium : false;
  const premiumExpirationDate = authState.user ? authState.user.premiumExpirationDate : null;

  const formatDate = (dateString) => {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', options).replace(/\//g, '.');
  };

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  useEffect(() => {
    const fetchCsrfToken = async () => {
      try {
        const csrfResponse = await api.get('/csrf-token', {
          withCredentials: true,
        });
        setCsrfToken(csrfResponse.data.csrfToken);
      } catch (error) {
        console.error('Error fetching CSRF token:', error);
      }
    };

    fetchCsrfToken();
  }, []);

  const handlePaymentSuccess = () => {
    setAuthState((prevState) => ({
      ...prevState,
      user: {
        ...prevState.user,
        isPremium: true,
      },
    }));
    setShowMessage(true);
  };

  return (
    <Container className="user-dashboard mt-4">
      <h2 className="text-center mb-4">Upravljačka ploča</h2>
      {authState.user && (
        <>
          {isPremium && (
            <>
              <p className="text-center text-success">
                Vi ste Premium korisnik! Vaša pretplata ističe {premiumExpirationDate ? formatDate(premiumExpirationDate) : 'unknown date'}.
              </p>
              {/* Koristi UserDashboardNav komponentu */}
              <UserDashboardNav />
            </>
          )}
          <Row className="mb-4 justify-content-center">
            <Col md={12} lg={8}>
              <Card className="shadow-sm">
                <Card.Header className="bg-primary text-white text-center">
                  <h3>Vrste korisnika i njihove mogućnosti</h3>
                </Card.Header>
                <Card.Body>
                  <Table striped bordered hover size="sm" responsive>
                    <thead className="table-dark">
                      <tr>
                        <th>Mogućnosti</th>
                        <th>Basic korisnik</th>
                        <th>Premium korisnik</th>
                      </tr>
                    </thead>
                    <tbody>
                      {[
                        { option: 'Maksimalno slika', basic: '4', premium: '10' },
                        { option: 'Uređivanje oglasa', basic: 'Da', premium: 'Da' },
                        { option: 'Brisanje oglasa', basic: 'Da', premium: 'Da' },
                        { option: 'Kontakt vlasnika oglasa', basic: 'Da', premium: 'Da' },
                        { option: 'Rezervacija', basic: 'Da', premium: 'Da' },
                        { option: 'Recenzija', basic: 'Da', premium: 'Da' },
                        { option: 'Istaknuti oglas', basic: 'Ne', premium: 'Da' },
                        { option: 'Automatsko obnavljanje', basic: 'Ne', premium: 'Da' },
                        { option: 'Pregled statistike', basic: 'Ne', premium: 'Da' },
                        { option: 'Dobijanje notifikacije', basic: 'Ne', premium: 'Da' },
                        { option: 'Dodavanje videa', basic: 'Ne', premium: 'Da' }
                      ].map((item, idx) => (
                        <React.Fragment key={idx}>
                          <tr className="d-none d-md-table-row">
                            <td>{item.option}</td>
                            <td><Badge bg={item.basic === 'Da' ? 'success' : 'danger'}>{item.basic}</Badge></td>
                            <td><Badge bg={item.premium === 'Da' ? 'success' : 'danger'}>{item.premium}</Badge></td>
                          </tr>
                          <tr className="d-md-none">
                            <td colSpan="3">
                              <div className="d-flex justify-content-between">
                                <strong>{item.option}</strong>
                                <span>Basic korisnik: <Badge bg={item.basic === 'Da' ? 'success' : 'danger'}>{item.basic}</Badge></span>
                              </div>
                              <div className="d-flex justify-content-between">
                                <span></span>
                                <span>Premium korisnik: <Badge bg={item.premium === 'Da' ? 'success' : 'danger'}>{item.premium}</Badge></span>
                              </div>
                            </td>
                          </tr>
                        </React.Fragment>
                      ))}
                    </tbody>
                  </Table>
                  {!isPremium && (
                    <div className="d-flex flex-column align-items-center mt-2">
                      <h5 className="mb-0 me-3"><strong>Nadogradi na Premium.</strong></h5>
                      <h6 className="mb-0 me-3">Odaberi način pretplate.</h6>
                      <div className="d-flex flex-column align-items-center mb-2">
                        <Button variant="outline-primary" onClick={() => setSelectedPrice(6)} className="mb-2">Mjesečno: 11.74 KM</Button>
                        <Button variant="outline-primary" onClick={() => setSelectedPrice(30)}>Godišnje: 58.67 KM</Button>
                      </div>
                      {selectedPrice > 0 && csrfToken && authState.user && (
                        <div className="mt-2">
                          <CheckoutButton price={selectedPrice} csrfToken={csrfToken} user={authState.user} onSuccess={handlePaymentSuccess} />
                        </div>
                      )}
                    </div>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </>
      )}
      <Row className="justify-content-center mt-4">
        <Col md={10} lg={8} className="d-flex justify-content-between">
          <Button variant="primary" onClick={() => navigate('/dashboard/manage-ads')} className="w-100 me-2 mb-2">Moji oglasi</Button>
          <Button variant="danger" onClick={handleLogout} className="w-100 mb-2">Odjava</Button>
        </Col>
      </Row>
      {showMessage && (
        <Row className="justify-content-center mt-4">
          <Col md={10} lg={8} className="text-center">
            <div className="success-message">
              Uplata je uspješno izvršena i sada ste Premium korisnik!
            </div>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default UserDashboard;
